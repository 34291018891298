import React, { useEffect, useState } from "react";
import { View, StyleSheet, StyleProp, ViewStyle, TextStyle } from "react-native";
import { RadioButton } from "@timhortons/common/src/components/atoms/radioButton";
import { Orientations } from "@timhortons/common/src/components/atoms/divider";
import { FontWeight, TextSize } from "@timhortons/common/src/components/atoms/text";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";

interface IRadioButtonOption {
  name: string;
  key: string;
  isSelected: boolean;
  onPress?: Function;
  isDisabled?: boolean;
}
interface IProps {
  radioOptions: IRadioButtonOption[];
  containerStyle?: StyleProp<ViewStyle>;
  radioViewStyle?: StyleProp<ViewStyle>;
  orientation?: Orientations;
  iconSize?: number;
  iconStyle?: object;
  iconSelectedStyle?: object;
  textStyle?: StyleProp<TextStyle>;
  fontWeight?: FontWeight;
  fontSize?: TextSize;
  isBorder?: boolean;
  selectedIconName?: IconNames;
  isMobile?: boolean;
  borderTextStyle?: StyleProp<TextStyle>;
  selectedRadioOptionStyle?: StyleProp<ViewStyle>;
  unSelectedRadioOptionStyle?: StyleProp<ViewStyle>;
  resetPrev?: boolean;
}
const getContainerStyle = (orientation: Orientations, containerStyle = {}): ViewStyle => {
  let themedStyle: StyleProp<ViewStyle>;
  if (orientation === Orientations.Horizontal) {
    themedStyle = styles.horizontal;
  } else {
    themedStyle = styles.vertical;
  }
  return StyleSheet.flatten([styles.container, themedStyle, containerStyle]);
};
function RadioGroup(props: IProps): React.ReactElement {
  const {
    containerStyle = {},
    radioOptions,
    isBorder,
    orientation = Orientations.Horizontal,
    selectedIconName = null,
    isMobile,
    borderTextStyle = {},
    selectedRadioOptionStyle,
    unSelectedRadioOptionStyle,
    resetPrev = false,
    radioViewStyle = {},
    ...rest
  } = props;
  const style = getContainerStyle(orientation, containerStyle);
  const [currentSelectedItem, setCurrentSelectedItem] = useState<string>("");
  useEffect(() => {
    if (radioOptions !== null && radioOptions !== undefined && !isMobile) {
      if (radioOptions.every((item) => item.isSelected === false) || resetPrev) {
        setCurrentSelectedItem("");
      }
    }
  }, [radioOptions]);
  console.log(radioOptions, "gfsfdhkgjsdfkjgsfdg");

  return (
    <View style={style}>
      {radioOptions?.map((option, index) => {
        let isChecked = !currentSelectedItem
          ? option.isSelected
          : currentSelectedItem === option.key;
        return isBorder ? (
          <View
            style={[
              (isChecked && styles.selectedRadioOption) || styles.unselectedRadioOption,
              isChecked ? selectedRadioOptionStyle : unSelectedRadioOptionStyle,
              option?.isDisabled && { opacity: 0.5 }
            ]}
            key={index}
            pointerEvents={option?.isDisabled ? "none" : "auto"}
          >
            <RadioButton
              {...rest}
              key={option.key}
              testId={testIds.radioGroup}
              onSelect={() => {
                option.onPress(option.key, setCurrentSelectedItem(option.key));
              }}
              isChecked={isChecked}
              radioText={option.name}
              fontSize={TextSize.Regular}
              fontWeight={FontWeight.Regular}
              selectedIconName={selectedIconName}
              textStyle={borderTextStyle}
            />
          </View>
        ) : (
          <RadioButton
            {...rest}
            key={option.key}
            testId={testIds.radioGroup}
            onSelect={() => {
              option.onPress(option.key, setCurrentSelectedItem(option.key));
            }}
            isChecked={isChecked}
            radioText={option.name}
            selectedIconName={selectedIconName}
            radioViewStyle={radioViewStyle}
          />
        );
      })}
    </View>
  );
  //
}
const styles = StyleSheet.create({
  container: {
    // margin: 10,
    flexWrap: "wrap"
  },
  horizontal: {
    // margin: 10,
    flexDirection: "row"
  },
  vertical: {
    flexDirection: "column"
  },
  selectedRadioOption: {
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: colorPallete.red1,
    paddingStart: 18,
    paddingEnd: 24,
    backgroundColor: colorPallete.variant14,
    marginEnd: 20
  },
  unselectedRadioOption: {
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: colorPallete.grey6,
    paddingStart: 18,
    paddingEnd: 24,
    backgroundColor: colorPallete.white3,
    marginEnd: 20
  }
});
export default RadioGroup;
